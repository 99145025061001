*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.all-\[unset\] {
  all: unset;
}

.buy-rubies {
  --tw-bg-opacity: 1;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 100%;
  height: 30px;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
}

.shop-content {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 20px;
  font-weight: 400;
  line-height: 15px;
}

.user-info-container {
  --tw-bg-opacity: 1;
  background-color: rgb(55 19 89 / var(--tw-bg-opacity, 1));
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  display: flex;
}

.rubies-coins-status {
  --tw-bg-opacity: 1;
  background-color: rgb(88 55 130 / var(--tw-bg-opacity, 1));
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rubies-coins-status-p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: 2px;
  font-size: 14px;
  font-weight: 700;
}

.buy-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  width: 100%;
  display: flex;
  overflow: auto;
}

.buy-container-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  min-width: 33.3333%;
  display: flex;
}

.buy-rubies-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 90px;
  height: 40px;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  border-style: none;
  font-weight: 400;
}

.buy-coins-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(244 147 49 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 90px;
  height: 40px;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  border-style: none;
  font-weight: 400;
}

.coins-for-rubies {
  --tw-text-opacity: 1;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-right: 21px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.membership {
  --tw-bg-opacity: 1;
  background-color: rgb(64 26 110 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 100%;
  height: 30px;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
}

.vip-pass-btn {
  --tw-text-opacity: 1;
  width: 144px;
  height: 40px;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  border-style: none;
  font-size: 16px;
  font-weight: 600;
}

.pass-content {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 17px;
  font-weight: 400;
  line-height: 15px;
}

.pass-price-p {
  --tw-text-opacity: 1;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.pass-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.pass-container-content {
  background-image: linear-gradient(45deg, #561395, #1b062f, #561395);
  border-radius: .125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 75px;
  display: flex;
}

.drop-down-font {
  letter-spacing: .1em;
  --tw-text-opacity: 1;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[1px\] {
  bottom: 1px;
}

.bottom-\[20px\] {
  bottom: 20px;
}

.bottom-\[31px\] {
  bottom: 31px;
}

.left-0 {
  left: 0;
}

.left-\[-10px\] {
  left: -10px;
}

.left-\[-14px\] {
  left: -14px;
}

.left-\[-25px\] {
  left: -25px;
}

.left-\[-4px\] {
  left: -4px;
}

.left-\[-9px\] {
  left: -9px;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[110px\] {
  left: 110px;
}

.left-\[120px\] {
  left: 120px;
}

.left-\[12px\] {
  left: 12px;
}

.left-\[152px\] {
  left: 152px;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[168px\] {
  left: 168px;
}

.left-\[1px\] {
  left: 1px;
}

.left-\[200px\] {
  left: 200px;
}

.left-\[250px\] {
  left: 250px;
}

.left-\[2px\] {
  left: 2px;
}

.left-\[300px\] {
  left: 300px;
}

.left-\[365px\] {
  left: 365px;
}

.left-\[37px\] {
  left: 37px;
}

.left-\[44\%\] {
  left: 44%;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[5px\] {
  left: 5px;
}

.left-\[70\%\] {
  left: 70%;
}

.left-\[70px\] {
  left: 70px;
}

.left-\[7px\] {
  left: 7px;
}

.left-\[85px\] {
  left: 85px;
}

.left-\[8px\] {
  left: 8px;
}

.left-\[95px\] {
  left: 95px;
}

.right-0 {
  right: 0;
}

.right-\[-15px\] {
  right: -15px;
}

.right-\[-45px\] {
  right: -45px;
}

.right-\[0px\] {
  right: 0;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[20px\] {
  right: 20px;
}

.right-\[5px\] {
  right: 5px;
}

.right-\[8px\] {
  right: 8px;
}

.top-0 {
  top: 0;
}

.top-\[-10px\] {
  top: -10px;
}

.top-\[-1px\] {
  top: -1px;
}

.top-\[-3px\] {
  top: -3px;
}

.top-\[-40px\] {
  top: -40px;
}

.top-\[-4px\] {
  top: -4px;
}

.top-\[-55px\] {
  top: -55px;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[-6px\] {
  top: -6px;
}

.top-\[-7px\] {
  top: -7px;
}

.top-\[0px\] {
  top: 0;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[117px\] {
  top: 117px;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[121px\] {
  top: 121px;
}

.top-\[12px\] {
  top: 12px;
}

.top-\[14px\] {
  top: 14px;
}

.top-\[150px\] {
  top: 150px;
}

.top-\[159px\] {
  top: 159px;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[196px\] {
  top: 196px;
}

.top-\[25px\] {
  top: 25px;
}

.top-\[27px\] {
  top: 27px;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[32px\] {
  top: 32px;
}

.top-\[36px\] {
  top: 36px;
}

.top-\[37px\] {
  top: 37px;
}

.top-\[45\%\] {
  top: 45%;
}

.top-\[4px\] {
  top: 4px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[55px\] {
  top: 55px;
}

.top-\[59px\] {
  top: 59px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[64px\] {
  top: 64px;
}

.top-\[7px\] {
  top: 7px;
}

.top-\[80px\] {
  top: 80px;
}

.top-\[83px\] {
  top: 83px;
}

.top-\[8px\] {
  top: 8px;
}

.top-\[9px\] {
  top: 9px;
}

.top-px {
  top: 1px;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.m-0 {
  margin: 0;
}

.m-\[10px\] {
  margin: 10px;
}

.m-\[20px\] {
  margin: 20px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[10px\] {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-\[7px\] {
  margin-left: 7px;
  margin-right: 7px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-\[20px\] {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-\[2px\] {
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.\!mt-\[5px\] {
  margin-top: 5px !important;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-\[-60px\] {
  margin-bottom: -60px;
}

.mb-\[100px\] {
  margin-bottom: 100px;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[180px\] {
  margin-bottom: 180px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[3px\] {
  margin-bottom: 3px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.mb-\[45px\] {
  margin-bottom: 45px;
}

.mb-\[5px\] {
  margin-bottom: 5px;
}

.mb-\[6px\] {
  margin-bottom: 6px;
}

.mb-\[80px\] {
  margin-bottom: 80px;
}

.mb-\[8px\] {
  margin-bottom: 8px;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[15px\] {
  margin-left: 15px;
}

.ml-\[20px\] {
  margin-left: 20px;
}

.ml-\[23px\] {
  margin-left: 23px;
}

.ml-\[3px\] {
  margin-left: 3px;
}

.ml-\[48px\] {
  margin-left: 48px;
}

.ml-\[5px\] {
  margin-left: 5px;
}

.ml-\[7px\] {
  margin-left: 7px;
}

.ml-\[8px\] {
  margin-left: 8px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-\[-23px\] {
  margin-right: -23px;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mr-\[15px\] {
  margin-right: 15px;
}

.mr-\[20px\] {
  margin-right: 20px;
}

.mr-\[3px\] {
  margin-right: 3px;
}

.mr-\[5px\] {
  margin-right: 5px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[-20px\] {
  margin-top: -20px;
}

.mt-\[-30px\] {
  margin-top: -30px;
}

.mt-\[0px\] {
  margin-top: 0;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[25px\] {
  margin-top: 25px;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[33px\] {
  margin-top: 33px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[40px\] {
  margin-top: 40px;
}

.mt-\[4px\] {
  margin-top: 4px;
}

.mt-\[50px\] {
  margin-top: 50px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.mt-\[720px\] {
  margin-top: 720px;
}

.mt-\[80px\] {
  margin-top: 80px;
}

.mt-auto {
  margin-top: auto;
}

.box-border {
  box-sizing: border-box;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.size-fit {
  width: fit-content;
  height: fit-content;
}

.\!h-\[100px\] {
  height: 100px !important;
}

.\!h-\[14px\] {
  height: 14px !important;
}

.\!h-\[40px\] {
  height: 40px !important;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[115px\] {
  height: 115px;
}

.h-\[11px\] {
  height: 11px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[173px\] {
  height: 173px;
}

.h-\[176px\] {
  height: 176px;
}

.h-\[17px\] {
  height: 17px;
}

.h-\[181px\] {
  height: 181px;
}

.h-\[185px\] {
  height: 185px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[209px\] {
  height: 209px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[23px\] {
  height: 23px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[273px\] {
  height: 273px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[31px\] {
  height: 31px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[37px\] {
  height: 37px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[39px\] {
  height: 39px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[425px\] {
  height: 425px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[49px\] {
  height: 49px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[700px\] {
  height: 700px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[74px\] {
  height: 74px;
}

.h-\[77px\] {
  height: 77px;
}

.h-\[81px\] {
  height: 81px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[89px\] {
  height: 89px;
}

.h-\[8px\] {
  height: 8px;
}

.h-\[90px\] {
  height: 90px;
}

.h-\[91px\] {
  height: 91px;
}

.h-\[97px\] {
  height: 97px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.min-h-\[303px\] {
  min-height: 303px;
}

.min-h-\[50vh\] {
  min-height: 50vh;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.min-h-\[70vh\] {
  min-height: 70vh;
}

.min-h-\[75vh\] {
  min-height: 75vh;
}

.min-h-full {
  min-height: 100%;
}

.\!w-\[100\%\] {
  width: 100% !important;
}

.\!w-\[100px\] {
  width: 100px !important;
}

.\!w-\[22px\] {
  width: 22px !important;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[104px\] {
  width: 104px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[115px\] {
  width: 115px;
}

.w-\[118px\] {
  width: 118px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[127px\] {
  width: 127px;
}

.w-\[142px\] {
  width: 142px;
}

.w-\[144px\] {
  width: 144px;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[158px\] {
  width: 158px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[174px\] {
  width: 174px;
}

.w-\[17px\] {
  width: 17px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[191px\] {
  width: 191px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[21px\] {
  width: 21px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[27px\] {
  width: 27px;
}

.w-\[28px\] {
  width: 28px;
}

.w-\[29px\] {
  width: 29px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[315px\] {
  width: 315px;
}

.w-\[31px\] {
  width: 31px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[330px\] {
  width: 330px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[37px\] {
  width: 37px;
}

.w-\[380px\] {
  width: 380px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[3px\] {
  width: 3px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[41px\] {
  width: 41px;
}

.w-\[422px\] {
  width: 422px;
}

.w-\[43px\] {
  width: 43px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[48px\] {
  width: 48px;
}

.w-\[49px\] {
  width: 49px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[511px\] {
  width: 511px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[590px\] {
  width: 590px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[61px\] {
  width: 61px;
}

.w-\[650px\] {
  width: 650px;
}

.w-\[65px\] {
  width: 65px;
}

.w-\[69px\] {
  width: 69px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[77px\] {
  width: 77px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[85px\] {
  width: 85px;
}

.w-\[89px\] {
  width: 89px;
}

.w-\[8px\] {
  width: 8px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[92\%\] {
  width: 92%;
}

.w-\[95px\] {
  width: 95px;
}

.w-\[97px\] {
  width: 97px;
}

.w-\[full\] {
  width: full;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[50\%\] {
  max-width: 50%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.translate-y-\[-50px\] {
  --tw-translate-y: -50px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[90\.00deg\] {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[2px\] {
  gap: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-wrap {
  text-wrap: wrap;
}

.text-balance {
  text-wrap: balance;
}

.rounded-\[13px\] {
  border-radius: 13px;
}

.rounded-\[4\.2px\] {
  border-radius: 4.2px;
}

.rounded-\[44\.36px\] {
  border-radius: 44.36px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[7px\] {
  border-radius: 7px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-\[0px\] {
  border-left-width: 0;
}

.border-l-\[1px\] {
  border-left-width: 1px;
}

.border-l-\[3px\] {
  border-left-width: 3px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-\[1px\] {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0, .border-t-\[0px\] {
  border-top-width: 0;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-\[\#17062f33\] {
  border-color: #17062f33;
}

.border-\[\#18062f\] {
  --tw-border-opacity: 1;
  border-color: rgb(24 6 47 / var(--tw-border-opacity, 1));
}

.border-\[\#1b062f\] {
  --tw-border-opacity: 1;
  border-color: rgb(27 6 47 / var(--tw-border-opacity, 1));
}

.border-\[\#c5c2c8\] {
  --tw-border-opacity: 1;
  border-color: rgb(197 194 200 / var(--tw-border-opacity, 1));
}

.border-\[\#d2ff00\] {
  --tw-border-opacity: 1;
  border-color: rgb(210 255 0 / var(--tw-border-opacity, 1));
}

.border-\[\#f73261\] {
  --tw-border-opacity: 1;
  border-color: rgb(247 50 97 / var(--tw-border-opacity, 1));
}

.border-\[\#fff\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-\[\#ffff9f\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 159 / var(--tw-border-opacity, 1));
}

.border-bordermain {
  --tw-border-opacity: 1;
  border-color: rgb(167 90 240 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-lime {
  --tw-border-opacity: 1;
  border-color: rgb(210 255 0 / var(--tw-border-opacity, 1));
}

.border-main {
  --tw-border-opacity: 1;
  border-color: rgb(27 6 47 / var(--tw-border-opacity, 1));
}

.border-mainC {
  --tw-border-opacity: 1;
  border-color: rgb(152 136 171 / var(--tw-border-opacity, 1));
}

.border-maindark {
  --tw-border-opacity: 1;
  border-color: rgb(70 56 89 / var(--tw-border-opacity, 1));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(215 50 97 / var(--tw-border-opacity, 1));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-l-borderlight {
  --tw-border-opacity: 1;
  border-left-color: rgb(52 20 90 / var(--tw-border-opacity, 1));
}

.border-r-\[\#c5c2c8\] {
  --tw-border-opacity: 1;
  border-right-color: rgb(197 194 200 / var(--tw-border-opacity, 1));
}

.\!bg-\[\#d73261\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-lime, .\!bg-lime-bg {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(210 255 0 / var(--tw-bg-opacity, 1)) !important;
}

.\!bg-red {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#18062f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(24 6 47 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1a73e8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 115 232 / var(--tw-bg-opacity, 1));
}

.bg-\[\#1b062f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 6 47 / var(--tw-bg-opacity, 1));
}

.bg-\[\#32f78c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(50 247 140 / var(--tw-bg-opacity, 1));
}

.bg-\[\#32f78d\]\/\[0\.3\] {
  background-color: #32f78d4d;
}

.bg-\[\#35204a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(53 32 74 / var(--tw-bg-opacity, 1));
}

.bg-\[\#452f5b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(69 47 91 / var(--tw-bg-opacity, 1));
}

.bg-\[\#482a6a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(72 42 106 / var(--tw-bg-opacity, 1));
}

.bg-\[\#4b3265\] {
  --tw-bg-opacity: 1;
  background-color: rgb(75 50 101 / var(--tw-bg-opacity, 1));
}

.bg-\[\#4c3368\] {
  --tw-bg-opacity: 1;
  background-color: rgb(76 51 104 / var(--tw-bg-opacity, 1));
}

.bg-\[\#662FAA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(102 47 170 / var(--tw-bg-opacity, 1));
}

.bg-\[\#83f9b9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(131 249 185 / var(--tw-bg-opacity, 1));
}

.bg-\[\#9432f7\]\/\[0\.3\] {
  background-color: #9432f74d;
}

.bg-\[\#9cd2ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(156 210 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#9cd2ff\]\/\[0\.3\] {
  background-color: #9cd2ff4d;
}

.bg-\[\#d2ff00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(210 255 0 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3f1f5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 241 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f5a6b8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 166 184 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f73261\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 50 97 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f73261\]\/\[0\.3\] {
  background-color: #f732614d;
}

.bg-\[\#f96d33\]\/\[0\.3\] {
  background-color: #f96d334d;
}

.bg-\[\#f9c2cb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 194 203 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffff9f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 159 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffffff26\] {
  background-color: #ffffff26;
}

.bg-\[rgba\(255\,255\,255\,0\.1\)\] {
  background-color: #ffffff1a;
}

.bg-\[rgba\(255\,255\,255\,0\.15\)\] {
  background-color: #ffffff26;
}

.bg-\[rgba\(255\,255\,255\,0\.2\)\] {
  background-color: #fff3;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-brightgreen {
  --tw-bg-opacity: 1;
  background-color: rgb(50 247 140 / var(--tw-bg-opacity, 1));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(244 147 49 / var(--tw-bg-opacity, 1));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(124 247 50 / var(--tw-bg-opacity, 1));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(146 146 146 / var(--tw-bg-opacity, 1));
}

.bg-lime {
  --tw-bg-opacity: 1;
  background-color: rgb(210 255 0 / var(--tw-bg-opacity, 1));
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(27 6 47 / var(--tw-bg-opacity, 1));
}

.bg-mainB {
  --tw-bg-opacity: 1;
  background-color: rgb(55 19 89 / var(--tw-bg-opacity, 1));
}

.bg-mainD {
  --tw-bg-opacity: 1;
  background-color: rgb(29 8 51 / var(--tw-bg-opacity, 1));
}

.bg-mainE {
  --tw-bg-opacity: 1;
  background-color: rgb(56 22 96 / var(--tw-bg-opacity, 1));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-opacity-15 {
  --tw-bg-opacity: .15;
}

.bg-\[url\(\/static\/img\/ellipse-786-stroke-3\.svg\)\] {
  background-image: url("ellipse-786-stroke-3.9cf090ce.svg");
}

.bg-\[url\(https\:\/\/dyatkw7msrzcb\.cloudfront\.net\/assets\/image-1\.png\)\] {
  background-image: url("https://dyatkw7msrzcb.cloudfront.net/assets/image-1.png");
}

.bg-cover {
  background-size: cover;
}

.bg-\[100\%_100\%\] {
  background-position: 100% 100%;
}

.bg-\[50\%_50\%\] {
  background-position: 50%;
}

.bg-center {
  background-position: center;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[15px\] {
  padding: 15px;
}

.p-\[1px\] {
  padding: 1px;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[30px\] {
  padding: 30px;
}

.p-\[3px\] {
  padding: 3px;
}

.p-\[6px\] {
  padding: 6px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}

.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.\!pl-\[10px\] {
  padding-left: 10px !important;
}

.\!pt-\[12px\] {
  padding-top: 12px !important;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pb-\[20px\] {
  padding-bottom: 20px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-\[40px\] {
  padding-bottom: 40px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pl-\[15px\] {
  padding-left: 15px;
}

.pl-\[1px\] {
  padding-left: 1px;
}

.pl-\[20px\] {
  padding-left: 20px;
}

.pl-\[25px\] {
  padding-left: 25px;
}

.pl-\[2px\] {
  padding-left: 2px;
}

.pl-\[30px\] {
  padding-left: 30px;
}

.pl-\[3px\] {
  padding-left: 3px;
}

.pl-\[45px\] {
  padding-left: 45px;
}

.pl-\[4px\] {
  padding-left: 4px;
}

.pl-\[5px\] {
  padding-left: 5px;
}

.pr-\[10px\] {
  padding-right: 10px;
}

.pr-\[30px\] {
  padding-right: 30px;
}

.pr-\[35px\] {
  padding-right: 35px;
}

.pr-\[4px\] {
  padding-right: 4px;
}

.pr-\[5px\] {
  padding-right: 5px;
}

.pt-0 {
  padding-top: 0;
}

.pt-\[10px\] {
  padding-top: 10px;
}

.pt-\[11px\] {
  padding-top: 11px;
}

.pt-\[12px\] {
  padding-top: 12px;
}

.pt-\[13px\] {
  padding-top: 13px;
}

.pt-\[15px\] {
  padding-top: 15px;
}

.pt-\[16px\] {
  padding-top: 16px;
}

.pt-\[17px\] {
  padding-top: 17px;
}

.pt-\[1px\] {
  padding-top: 1px;
}

.pt-\[20px\] {
  padding-top: 20px;
}

.pt-\[25px\] {
  padding-top: 25px;
}

.pt-\[29px\] {
  padding-top: 29px;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.pt-\[30px\] {
  padding-top: 30px;
}

.pt-\[35px\] {
  padding-top: 35px;
}

.pt-\[3px\] {
  padding-top: 3px;
}

.pt-\[40px\] {
  padding-top: 40px;
}

.pt-\[4px\] {
  padding-top: 4px;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.pt-\[6px\] {
  padding-top: 6px;
}

.pt-\[7px\] {
  padding-top: 7px;
}

.pt-\[8px\] {
  padding-top: 8px;
}

.pt-\[9px\] {
  padding-top: 9px;
}

.\!text-left {
  text-align: left !important;
}

.text-left {
  text-align: left;
}

.\!text-center {
  text-align: center !important;
}

.text-center {
  text-align: center;
}

.\!text-right {
  text-align: right !important;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.font-\[\#fff\] {
  font-family: #fff;
}

.font-anton {
  font-family: Anton, sans-serif;
}

.font-chakra {
  font-family: Chakra Petch, Helvetica, sans-serif;
}

.\!text-\[14px\] {
  font-size: 14px !important;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[1px\] {
  font-size: 1px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-\[35px\] {
  font-size: 35px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[60px\] {
  font-size: 60px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-\[16px\] {
  font-weight: 16px;
}

.font-\[20px\] {
  font-weight: 20px;
}

.font-\[400\] {
  font-weight: 400;
}

.font-\[600\] {
  font-weight: 600;
}

.font-\[700\] {
  font-weight: 700;
}

.font-\[800\] {
  font-weight: 800;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-\[15px\] {
  line-height: 15px;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[26px\] {
  line-height: 26px;
}

.leading-\[normal\] {
  line-height: normal;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.\!text-\[\#1b062f\] {
  --tw-text-opacity: 1 !important;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1)) !important;
}

.\!text-\[\#d2ff00\] {
  --tw-text-opacity: 1 !important;
  color: rgb(210 255 0 / var(--tw-text-opacity, 1)) !important;
}

.\!text-\[\#d73261\] {
  --tw-text-opacity: 1 !important;
  color: rgb(215 50 97 / var(--tw-text-opacity, 1)) !important;
}

.\!text-lime {
  --tw-text-opacity: 1 !important;
  color: rgb(210 255 0 / var(--tw-text-opacity, 1)) !important;
}

.\!text-main {
  --tw-text-opacity: 1 !important;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1)) !important;
}

.\!text-red {
  --tw-text-opacity: 1 !important;
  color: rgb(215 50 97 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-\[\#18062f\] {
  --tw-text-opacity: 1;
  color: rgb(24 6 47 / var(--tw-text-opacity, 1));
}

.text-\[\#1b062d\] {
  --tw-text-opacity: 1;
  color: rgb(27 6 45 / var(--tw-text-opacity, 1));
}

.text-\[\#1b062f\] {
  --tw-text-opacity: 1;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
}

.text-\[\#32f78c\] {
  --tw-text-opacity: 1;
  color: rgb(50 247 140 / var(--tw-text-opacity, 1));
}

.text-\[\#7cf732\] {
  --tw-text-opacity: 1;
  color: rgb(124 247 50 / var(--tw-text-opacity, 1));
}

.text-\[\#a39bac\] {
  --tw-text-opacity: 1;
  color: rgb(163 155 172 / var(--tw-text-opacity, 1));
}

.text-\[\#d2ff00\] {
  --tw-text-opacity: 1;
  color: rgb(210 255 0 / var(--tw-text-opacity, 1));
}

.text-\[\#f73261\] {
  --tw-text-opacity: 1;
  color: rgb(247 50 97 / var(--tw-text-opacity, 1));
}

.text-\[\#ff0000\] {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity, 1));
}

.text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-\[\#ffff9f\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 159 / var(--tw-text-opacity, 1));
}

.text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-brightgreen {
  --tw-text-opacity: 1;
  color: rgb(50 247 140 / var(--tw-text-opacity, 1));
}

.text-gold {
  --tw-text-opacity: 1;
  color: rgb(244 147 49 / var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(124 247 50 / var(--tw-text-opacity, 1));
}

.text-lime {
  --tw-text-opacity: 1;
  color: rgb(210 255 0 / var(--tw-text-opacity, 1));
}

.text-main {
  --tw-text-opacity: 1;
  color: rgb(27 6 47 / var(--tw-text-opacity, 1));
}

.text-mainC {
  --tw-text-opacity: 1;
  color: rgb(152 136 171 / var(--tw-text-opacity, 1));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(215 50 97 / var(--tw-text-opacity, 1));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.accent-main {
  accent-color: #1b062f;
}

.opacity-10 {
  opacity: .1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.opacity-90 {
  opacity: .9;
}

.opacity-\[0\.15\] {
  opacity: .15;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.shadow-\[0px_0px_30px_\#00000080\] {
  --tw-shadow: 0px 0px 30px #00000080;
  --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\[background\:linear-gradient\(180deg\,rgb\(27\,6\,47\)_0\%\,rgba\(27\,6\,47\,0\)_100\%\)\] {
  background: linear-gradient(#1b062f 0%, #1b062f00 100%);
}

.\[font-family\:\'Anton\'\,Helvetica\] {
  font-family: Anton, Helvetica;
}

.\[font-family\:\'Chakra_Petch\'\,Helvetica\] {
  font-family: Chakra Petch, Helvetica;
}

.\[font-family\:\'Chakra_Petch-Bold\'\,Helvetica\] {
  font-family: Chakra Petch-Bold, Helvetica;
}

.\[font-family\:\'Chakra_Petch-Regular\'\,Helvetica\] {
  font-family: Chakra Petch-Regular, Helvetica;
}

@keyframes riseAndSpin {
  0% {
    transform: scale(1)translateY(0)rotateY(0)rotateX(0);
  }

  30% {
    transform: scale(2)translateY(-15px)rotateY(0)rotateX(0);
  }

  100% {
    transform: scale(1)translateY(0)rotateY(720deg)rotateX(0);
  }
}

.rise-and-spin {
  animation: 3s ease-in-out riseAndSpin;
}

.amount-increse-animation {
  color: #ffc300;
  font-weight: 700;
}

.amount-decrese-animation {
  color: red;
  font-weight: 700;
}

@keyframes fadeAnimation {
  0%, 100% {
    opacity: .3;
  }

  50% {
    opacity: 1;
  }
}

:root {
  --bg: red;
  --c8-plum: #18062f;
  --collection-1-c8-green: #19696a;
  --collection-1-c8-lime: #d2ff00;
  --collection-1-white: #fff;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menuLink {
  color: #fff;
  text-decoration: none;
}

.menuLink:hover {
  color: #c2e809;
}

.menuLink-dark {
  color: #1b062f;
  text-decoration: none;
}

.c8Page {
  background: radial-gradient(circle at 100% 0, #40196e 0%, #7b1fa200 70%), radial-gradient(circle at 0 100%, #40196e 0%, #7b1fa200 70%), #1b062f;
}

.footerlink {
  color: #fff;
  text-decoration: none;
}

.footerlink:hover {
  color: #c2e809;
  text-decoration: underline;
}

#content {
  margin-bottom: 80px;
}

#content, #sitefooter, #headerMatch {
  color: #fff;
  text-transform: uppercase;
  padding: 0 14px;
  font-family: Anton, Helvetica;
  font-weight: normal;
  position: relative;
  top: 0;

  & h1 {
    color: #fff;
  }

  & h2 {
    color: #d2ff00;
  }
}

#content, #sitefooter, #headerMatch, #splash, .ReactModalPortal {
  & .font-chakra {
    font-family: Chakra Petch, Helvetica;
    font-weight: normal;
  }

  & .font-chakra.bold, & .font-chakra .bold {
    font-weight: bold;
  }

  & .font-anton {
    text-transform: uppercase;
    font-family: Anton, Helvetica;
    font-weight: normal;
  }

  & .font-anton.bold, & .font-anton .bold {
    font-weight: bold;
  }

  & #submenu {
    color: #000;
    background-color: #fff;
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    display: flex;
    position: relative;
  }

  & #submenu .option {
    border-radius: 5px;
    flex-basis: auto;
    padding: 8px 10px;
  }

  & #submenu .option.on {
    color: #000;
    background-color: #d2ff00;
  }

  & .MatchCard {
    margin-top: 30px;
  }

  & .MatchCard .header {
    background-color: #ffffff26;
    width: 100%;
    display: flex;
  }

  & .MatchCard .footer {
    background-color: #ffffff26;
    width: 100%;
    margin-top: 2px;
    display: flex;
  }

  & .MatchCard .footer .action {
    padding: 13px 7px;
    font-family: Chakra Petch, Helvetica;
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
  }

  & .MatchCard .footer .prompt {
    text-align: right;
    background-color: #d2ff00;
    margin-left: auto;
    padding: 11px 10px;
  }

  & .MatchCard .header .title {
    color: #000;
    background-color: #d2ff00;
    max-width: 65%;
    padding: 8px 10px;
    font-size: 20px;
    display: inline-block;
  }

  & .MatchCard .header .schedule {
    text-align: right;
    margin-left: auto;
    padding: 12px 7px;
    font-family: Chakra Petch, Helvetica;
    font-size: 14px;
    font-weight: normal;
  }

  & .MatchCard .header .schedule.live {
    color: #f73261;
    display: flex;
  }

  & .live-indicator-solid {
    background-color: #f7326199;
    border: 1px solid #f7326199;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    margin-top: 5px;
    margin-left: 10px;
    animation: 1.5s infinite fadeAnimation;
    position: relative;
    top: 0;
    left: 5px;
  }

  & .live-indicator-solid:first-child {
    animation-delay: 0s;
  }

  & .live-indicator-solid:nth-child(2) {
    animation-delay: .5s;
  }

  & .live-indicator-solid:nth-child(3) {
    animation-delay: 1s;
  }

  & .live-indicator {
    border: 1px solid #f7326199;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    position: relative;
    top: 0;
    left: 5px;
  }

  & .live-indicator .inner {
    background-color: #f73261;
    border-radius: 4.2px;
    width: 8px;
    height: 8px;
    position: relative;
    top: 2px;
    left: 2px;
  }

  & .MatchCard .body {
    min-width: 100%;
    padding-top: 2px;
    display: flex;
  }

  & .MatchCard .body .homeTeam, & .MatchCard .body .awayTeam {
    color: #1b062f;
    background-color: #fff;
    width: calc(50% - 1px);
    padding: 10px;
    font-family: Chakra Petch, Helvetica;
    font-size: 14px;
    font-weight: normal;
  }

  & .MatchCard .body .homeTeam {
    margin-right: 1px;
  }

  & .MatchCard .body .awayTeam {
    margin-left: 1px;
  }

  & .MatchCard .body .homeTeam .name, & .MatchCard .body .awayTeam .name {
    min-height: 28px;
  }

  & .MatchCard .body .homeTeam .top, & .MatchCard .body .awayTeam .top {
    min-height: 50px;
    display: flex;
  }

  & .MatchCard .body .homeTeam .top .scoreline, & .MatchCard .body .awayTeam .top .scoreline {
    text-align: right;
    margin-left: auto;
    font-weight: bold;
  }
}

#sitefooter {
  color: #fff;
  text-transform: uppercase;
  font-family: Anton, Helvetica;
  font-weight: normal;
  transform: translateY(-50px);
}

.yellowBox {
  color: #1b062f;
  text-transform: uppercase;
  background-color: #d2ff00;
  padding: 5px;
  font-family: Anton, Helvetica;
  font-size: 14px;
  font-weight: normal;
  display: inline-block;
}

.subTitle {
  text-transform: uppercase;
  margin-left: 10px;
  padding-top: 4px;
}

.dateDisplay {
  font-family: Chakra Petch, Helvetica;
  font-size: 14px;
  font-weight: normal;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.select-wrapper {
  background-color: #fff;
  border: 1px solid #1b062f;
  border-radius: 0;
  display: inline-block;
  position: relative;
  right: 0;
  overflow: hidden;
}

.select-wrapper:after {
  content: "";
  background-color: #1b062f;
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
}

select.dropdown {
  font-family: Chakra Petch, Helvetica;
  font-weight: normal;
  font-size: inherit;
  appearance: none;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"13\" height=\"12\" viewBox=\"0 0 13 12\" fill=\"none\"><path d=\"M1.22266 5.66016L6.72266 11.1602L12.2227 5.66016\" stroke=\"%2318062F\" /></svg>") 0 0 / 13px 12px no-repeat;
  border: none;
  width: 100%;
  padding: 8px 40px 8px 8px;
  background-position: right 8px top 8px !important;
}

select.dropdown#country {
  background-position: right 8px top 12px !important;
}

.select-wrapper {
  width: auto;
}

.enteremail {
  color: #1b062f;
  background: #fff;
  border: none;
  width: 100%;
  height: 36px;
  font-family: Chakra Petch, Helvetica;
  font-size: 14px;
  font-weight: normal;
}

.enteremail::placeholder {
  text-align: left;
  padding-left: 5px;
}

.enteremail:focus {
  outline: none;
}

.enteremail2 {
  color: #37224d;
  background: none;
  border: none;
  width: 100%;
  height: 36px;
  font-family: Chakra Petch, Helvetica;
  font-size: 14px;
  font-weight: normal;
}

.enteremail2::placeholder {
  text-align: left;
  padding-left: 5px;
}

.enteremail2:focus {
  outline: none;
}

.standingInput {
  background: none;
  border: none;
  width: 100%;
  margin-left: 10px;
  font-family: Chakra Petch, Helvetica;
  font-size: 14px;
  font-weight: normal;
}

.standingInput:focus {
  outline: none;
}

#breakout {
  box-sizing: border-box;
  width: calc(100% + 30px);
  height: 358px;
  position: absolute;
  top: 170px;
  left: -15px;
}

.diagonal-gradient {
  background: linear-gradient(45deg, #321f45, #4b2c70);
}

.blogpost {
  color: #1b062f;
  padding-bottom: 100px;
  font-family: Chakra Petch, Helvetica;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;

  & p {
    padding-bottom: 10px;
  }

  & hr {
    opacity: 1;
    background-color: #0000;
    background-image: radial-gradient(currentColor 1px, #0000 1px);
    background-position: 1px 1px;
    background-repeat: repeat;
    background-size: 5px 5px;
    border: none;
    height: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
    position: relative;
  }

  & a {
    color: #1b062f;
    text-decoration: underline;
  }

  & a:hover {
    text-decoration: underline;
  }

  & strong {
    font-family: inherit;
    font-size: 100%;
    font-weight: 700;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
  }

  & img {
    max-width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
}

.modal {
  text-align: center;
  z-index: 1000;
  text-transform: uppercase;
  background: #fff;
  border: 3px solid #401a6e;
  width: 300px;
  margin-right: -50%;
  padding: 20px;
  font-family: Anton, Helvetica;
  font-weight: normal;
  position: absolute;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

.modal h2 {
  text-align: center;
  text-transform: uppercase;
  background-color: #d2ff00;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 999;
  background-color: #000000bf;
  position: fixed;
  inset: 0;
}

.share-buttons {
  justify-content: space-around;
  margin-top: 30px;
  display: flex;
}

.copy-link button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.share-button {
  all: unset;
  cursor: pointer !important;

  & .btn {
    text-transform: uppercase;
    color: #1b062f;
    background-color: #d2ff00;
    border: 1px solid #1b062f;
    padding: 14px 20px;
    font-family: Anton, Helvetica;
    font-size: 18px;
    font-weight: normal;
  }
}

.customGoogleBtn {
  color: #444;
  white-space: nowrap;
  background: #fff;
  border: thin solid #888;
  border-radius: 5px;
  width: 190px;
  display: inline-block;
  box-shadow: 1px 1px 1px gray;
}

.ReactModalPortal {
  & button {
    all: unset;
    color: #fff;
    cursor: pointer;
  }
}

@media (width <= 400px) {
  #Modalspinner {
    width: calc(100vw - 20px) !important;
  }
}

.ReactModal__Content {
  overflow: hidden !important;
}

.diagonal-gradient {
  background: none;
  width: 100%;
  height: 100%;
}

@media (width <= 640px) {
  .diagonal-gradient {
    background: linear-gradient(to top right, #3e196b, #1d0833);
  }
}

.checked\:bg-red:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(215 50 97 / var(--tw-bg-opacity, 1));
}

.hover\:border-limehover:hover {
  --tw-border-opacity: 1;
  border-color: rgb(194 232 9 / var(--tw-border-opacity, 1));
}

.hover\:bg-limehover:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 232 9 / var(--tw-bg-opacity, 1));
}

.hover\:bg-mainB:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 19 89 / var(--tw-bg-opacity, 1));
}

.hover\:text-limehover:hover {
  --tw-text-opacity: 1;
  color: rgb(194 232 9 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (width >= 640px) {
  .sm\:min-h-\[80px\] {
    min-height: 80px;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:bottom-\[0px\] {
    bottom: 0;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-\[100px\] {
    left: 100px;
  }

  .md\:left-\[15px\] {
    left: 15px;
  }

  .md\:right-\[0px\] {
    right: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[156px\] {
    top: 156px;
  }

  .md\:top-\[30px\] {
    top: 30px;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:\!mt-\[595px\] {
    margin-top: 595px !important;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:mb-\[0\] {
    margin-bottom: 0;
  }

  .md\:mb-\[10px\] {
    margin-bottom: 10px;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-\[25px\] {
    margin-left: 25px;
  }

  .md\:ml-\[30px\] {
    margin-left: 30px;
  }

  .md\:ml-\[50px\] {
    margin-left: 50px;
  }

  .md\:ml-\[70px\] {
    margin-left: 70px;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mr-\[25px\] {
    margin-right: 25px;
  }

  .md\:mr-\[50px\] {
    margin-right: 50px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-\[0px\] {
    margin-top: 0;
  }

  .md\:mt-\[100px\] {
    margin-top: 100px;
  }

  .md\:mt-\[10px\] {
    margin-top: 10px;
  }

  .md\:mt-\[20px\] {
    margin-top: 20px;
  }

  .md\:mt-\[37px\] {
    margin-top: 37px;
  }

  .md\:mt-\[3px\] {
    margin-top: 3px;
  }

  .md\:mt-\[40px\] {
    margin-top: 40px;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[370px\] {
    height: 370px;
  }

  .md\:h-\[38px\] {
    height: 38px;
  }

  .md\:h-\[50px\] {
    height: 50px;
  }

  .md\:h-\[52px\] {
    height: 52px;
  }

  .md\:h-\[575px\] {
    height: 575px;
  }

  .md\:h-\[60px\] {
    height: 60px;
  }

  .md\:h-\[65px\] {
    height: 65px;
  }

  .md\:h-\[70px\] {
    height: 70px;
  }

  .md\:h-\[72px\] {
    height: 72px;
  }

  .md\:h-\[74px\] {
    height: 74px;
  }

  .md\:h-\[80px\] {
    height: 80px;
  }

  .md\:h-\[94px\] {
    height: 94px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-\[0\] {
    width: 0;
  }

  .md\:w-\[100\%\] {
    width: 100%;
  }

  .md\:w-\[100px\] {
    width: 100px;
  }

  .md\:w-\[120px\] {
    width: 120px;
  }

  .md\:w-\[125px\] {
    width: 125px;
  }

  .md\:w-\[150px\] {
    width: 150px;
  }

  .md\:w-\[190px\] {
    width: 190px;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[210px\] {
    width: 210px;
  }

  .md\:w-\[230px\] {
    width: 230px;
  }

  .md\:w-\[250px\] {
    width: 250px;
  }

  .md\:w-\[265px\] {
    width: 265px;
  }

  .md\:w-\[310px\] {
    width: 310px;
  }

  .md\:w-\[322px\] {
    width: 322px;
  }

  .md\:w-\[365px\] {
    width: 365px;
  }

  .md\:w-\[370px\] {
    width: 370px;
  }

  .md\:w-\[372\] {
    width: 372px;
  }

  .md\:w-\[40px\] {
    width: 40px;
  }

  .md\:w-\[412px\] {
    width: 412px;
  }

  .md\:w-\[413px\] {
    width: 413px;
  }

  .md\:w-\[414px\] {
    width: 414px;
  }

  .md\:w-\[49\%\] {
    width: 49%;
  }

  .md\:w-\[50\%\] {
    width: 50%;
  }

  .md\:w-\[50px\] {
    width: 50px;
  }

  .md\:w-\[58\%\] {
    width: 58%;
  }

  .md\:w-\[600px\] {
    width: 600px;
  }

  .md\:w-\[60px\] {
    width: 60px;
  }

  .md\:w-\[650px\] {
    width: 650px;
  }

  .md\:w-\[670px\] {
    width: 670px;
  }

  .md\:w-\[72px\] {
    width: 72px;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:w-\[80px\] {
    width: 80px;
  }

  .md\:w-\[85px\] {
    width: 85px;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-\[40\%\] {
    min-width: 40%;
  }

  .md\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:border-r-\[0px\] {
    border-right-width: 0;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-\[10px\] {
    padding: 10px;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:pl-\[60px\] {
    padding-left: 60px;
  }

  .md\:pr-\[20px\] {
    padding-right: 20px;
  }

  .md\:pt-\[125px\] {
    padding-top: 125px;
  }

  .md\:pt-\[12px\] {
    padding-top: 12px;
  }

  .md\:pt-\[22px\] {
    padding-top: 22px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-\[14px\] {
    font-size: 14px;
  }

  .md\:text-\[16px\] {
    font-size: 16px;
  }

  .md\:text-\[17px\] {
    font-size: 17px;
  }

  .md\:text-\[20px\] {
    font-size: 20px;
  }

  .md\:text-\[24px\] {
    font-size: 24px;
  }

  .md\:text-\[27px\] {
    font-size: 27px;
  }

  .md\:text-\[30px\] {
    font-size: 30px;
  }

  .md\:text-\[40px\] {
    font-size: 40px;
  }

  .md\:text-\[55px\] {
    font-size: 55px;
  }

  .md\:text-\[80px\] {
    font-size: 80px;
  }
}

@media (width >= 1024px) {
  .lg\:top-\[-45px\] {
    top: -45px;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:h-\[120px\] {
    height: 120px;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:w-\[230px\] {
    width: 230px;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-\[36px\] {
    font-size: 36px;
  }

  .lg\:text-\[70px\] {
    font-size: 70px;
  }
}

@media (width >= 1280px) {
  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
/*# sourceMappingURL=index.a6b515db.css.map */
